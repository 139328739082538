
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';

@Component({
    name: "DeveloperCenter",
    components: {
        SingleHeader,
        SingleFooter,
    },
})
export default class DeveloperCenter extends Vue {
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private changeIndex: number = 157; // 157 156 158
    private resourceListAll: any = [];
    private resourceList: any = [];
    private moreFlag: boolean = false;
    private hideFlag: boolean = false;
    private handleType: any = 'prev'; // next prev
    private moreDevDocUrl = 'https://shdoc.morewiscloud.com/mcdocs/index.html';
    private moreDevDocId: any = '';

    private created() {
        this.getDocList();
        this.getResourceList(this.changeIndex);
    }
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private getResourceList(category: number) {
        this.$httpService.getData({
            category,
            notLoading: true,
        }, '/apiProxy/api/frontend/resource')
        .then((res: any) => {
            this.resourceListAll = res;
            this.resourceList = this.resourceListAll.slice(0, 6);
            this.moreFlag = true;
            this.hideFlag = false;
        });
    }
    private changeDev(id: number) {
        if (id !== this.changeIndex) {
            this.changeIndex = id;
            this.getResourceList(this.changeIndex);
        }
    }
    private moreList() {
        this.resourceList = [...this.resourceListAll];
        this.moreFlag = false;
        this.hideFlag = true;
    }
    private hideList() {
        this.resourceList = this.resourceListAll.slice(0, 6);
        this.moreFlag = true;
        this.hideFlag = false;
    }
    private async toLink(url: any) {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        const winOpen: any = window.open("", "_blank");
        winOpen.location = url;
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    // 开发者社区下一页
    private docForward() {
        this.handleType = 'next';
    }
    // 上一页
    private docBack() {
        this.handleType = 'prev';
    }
    // 下载
    private getDetail(id: any) {
        this.$router
        .push({
            path: '/downloadRes',
            query: {
                category: id,
            },
        }).catch((err) => err);
    }
    private getDocList() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/document`)
        .then((res: any) => {
            console.log('-= res =-', res);
            for (const key in res) {
                if (Object.prototype.hasOwnProperty.call(res, key)) {
                    const element = res[key];
                    if (element && element.length) {
                        element.forEach((docInfo: any) => {
                            const documentLink = docInfo.documentLink;
                            if (documentLink &&  documentLink.indexOf(this.moreDevDocUrl) !== -1) {
                                this.moreDevDocId = docInfo.id;
                            }
                        });
                    }
                }
            }
        });
    }
}
